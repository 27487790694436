import React, { useEffect } from "react";
import { Line } from "react-chartjs-2";
import { useState } from "react";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Height } from "@mui/icons-material";
import axios from "axios";
Chart.register(CategoryScale);
const api = process.env.REACT_APP_API_URL;

function LineChart({ cdata }) {
  const Data = [
    {
      id: 1,
      month: "Jan",
      weight: 44,
      height: 130,
      bmi: 28,
      head_curc: 60,
      chest_curcum: 120,
      mid_air: 25,
    },
    {
      id: 1,
      month: "Feb",
      weight: 55,
      height: 135,
      bmi: 24,
      head_curc: 66,
      chest_curcum: 130,
      mid_air: 28,
    },
    {
      id: 1,
      month: "Mar",
      weight: 60,
      height: 140,
      bmi: 22,
      head_curc: 70,
      chest_curcum: 140,
      mid_air: 32,
    },
    {
      id: 1,
      month: "Apr",
      weight: 65,
      height: 145,
      bmi: 21,
      head_curc: 79,
      chest_curcum: 150,
      mid_air: 35,
    },
    {
      id: 1,
      month: "May",
      weight: 70,
      height: 144,
      bmi: 26,
      head_curc: 80,
      chest_curcum: 139,
      mid_air: 40,
    },
  ];



  const [chartData, setChartData] = useState({
    labels: cdata.map((data) => data.month),

    datasets: [
      {
        label: "Users Gained ",
        data: cdata.map((data) => data.height),
        backgroundColor: [
          "rgba(75,192,192,1)",
          "#50AF95",
          "#f3ba2f",
          "#2a71d0",
        ],
        borderColor: "black",
        borderWidth: 2,
      },
    ],
  });
  const [weight, setweight] = useState({
    labels: cdata.map((data) => data.month),

    datasets: [
      {
        label: "Users Gained ",
        data: cdata.map((data) => data.weight),
        backgroundColor: [
          "rgba(75,192,192,1)",
          "#50AF95",
          "#f3ba2f",
          "#2a71d0",
        ],
        borderColor: "black",
        borderWidth: 2,
      },
    ],
  });
  const [bmi, setbmi] = useState({
    labels: cdata.map((e) => e.month),

    datasets: [
      {
        label: "Users Gained ",
        data: cdata.map((e) => e.bmi),
        backgroundColor: [
          "rgba(75,192,192,1)",
          "#50AF95",
          "#f3ba2f",
          "#2a71d0",
        ],
        borderColor: "black",
        borderWidth: 2,
      },
    ],
  });

  // console.log(bmi);
  const [head_curc, sethead_curc] = useState({
    labels: cdata.map((data) => data.month),

    datasets: [
      {
        label: "Users Gained ",
        data: cdata.map((data) => data.head_curc),
        backgroundColor: [
          "rgba(75,192,192,1)",
          "#50AF95",
          "#f3ba2f",
          "#2a71d0",
        ],
        borderColor: "black",
        borderWidth: 2,
      },
    ],
  });

  const [mid_air, setmid_air] = useState({
    labels: cdata.map((data) => data.month),

    datasets: [
      {
        label: "Users Gained ",
        data: cdata.map((data) => data.mid_air),
        backgroundColor: [
          "rgba(75,192,192,1)",
          "#50AF95",
          "#f3ba2f",
          "#2a71d0",
        ],
        borderColor: "black",
        borderWidth: 2,
      },
    ],
  });
  console.log("🚀 ~ LineChart ~ mid_air:", mid_air)

  const [ches_curc, setches_curc] = useState({
    labels: cdata.map((data) => data.month),

    datasets: [
      {
        label: "Users Gained ",
        data: cdata.map((data) => data.chest_curcum),
        backgroundColor: [
          "rgba(75,192,192,1)",
          "#50AF95",
          "#f3ba2f",
          "#2a71d0",
        ],
        borderColor: "black",
        borderWidth: 2,
      },
    ],
  });

  
  const [arm_span, setarm_span] = useState({
    labels: cdata.map((data) => data.month),

    datasets: [
      {
        label: "Users Gained ",
        data: cdata.map((data) => data.arm),
        backgroundColor: [
          "rgba(75,192,192,1)",
          "#50AF95",
          "#f3ba2f",
          "#2a71d0",
        ],
        borderColor: "black",
        borderWidth: 2,
      },
    ],
  });

  const [UpperSegment, setUpperSegment] = useState({
    labels: cdata.map((data) => data.month),

    datasets: [
      {
        label: "Users Gained ",
        data: cdata.map((data) => data.upsegment),
        backgroundColor: [
          "rgba(75,192,192,1)",
          "#50AF95",
          "#f3ba2f",
          "#2a71d0",
        ],
        borderColor: "black",
        borderWidth: 2,
      },
    ],
  });

  const [lSegment, setlSegment] = useState({
    labels: cdata.map((data) => data.month),

    datasets: [
      {
        label: "Users Gained ",
        data: cdata.map((data) => data.lowsegment),
        backgroundColor: [
          "rgba(75,192,192,1)",
          "#50AF95",
          "#f3ba2f",
          "#2a71d0",
        ],
        borderColor: "black",
        borderWidth: 2,
      },
    ],
  });

  const [uratio, setUratio] = useState({
    labels: cdata.map((data) => data.month),

    datasets: [
      {
        label: "Users Gained ",
        data: cdata.map((data) => data.ratio),
        backgroundColor: [
          "rgba(75,192,192,1)",
          "#50AF95",
          "#f3ba2f",
          "#2a71d0",
        ],
        borderColor: "black",
        borderWidth: 2,
      },
    ],
  });


  return (
    <>
      {console.log(cdata)}
      <div className="grid gap-4 grid-cols-2">
        <div
          className="chart-container"
          style={{ height: "20rem", width: "35rem" }}
        >
          {console.log(bmi)}
          <Line
            data={chartData}
            options={{
              maintainAspectRatio: false,

              scales: {
                y: {
                  max: 150,
                  min: 0,
                  ticks: {
                    stepSize: 5,
                  },
                },
              },
              plugins: {
                title: {
                  display: true,
                  text: "Height(CM)",
                },
                legend: {
                  display: false,
                },
              },
            }}
          />
        </div>
        <div
          className="chart-container"
          style={{ height: "20rem", width: "35rem" }}
        >
          <Line
            data={weight}
            options={{
              maintainAspectRatio: false,

              scales: {
                y: {
                  max: 100,
                  min: 0,
                  ticks: {
                    stepSize: 5,
                  },
                },
              },
              plugins: {
                title: {
                  display: true,
                  text: "Weight(KG)",
                },
                legend: {
                  display: false,
                },
              },
            }}
          />
        </div>
        <div
          className="chart-container"
          style={{ height: "20rem", width: "35rem" }}
        >
          <Line
            data={bmi}
            options={{
              maintainAspectRatio: false,

              scales: {
                y: {
                  max: 50,
                  min: 0,
                  ticks: {
                    stepSize: 2,
                  },
                },
              },
              plugins: {
                title: {
                  display: true,
                  text: "BMI",
                },
                legend: {
                  display: false,
                },
              },
            }}
          />
        </div>
        <div
          className="chart-container"
          style={{ height: "20rem", width: "35rem" }}
        >
          <Line
            data={head_curc}
            options={{
              maintainAspectRatio: false,

              scales: {
                y: {
                  max: 80,
                  min: 0,
                  ticks: {
                    stepSize: 5,
                  },
                },
              },
              plugins: {
                title: {
                  display: true,
                  text: "Head Circumference (CMS)",
                },
                legend: {
                  display: false,
                },
              },
            }}
          />
        </div>
        <div
          className="chart-container"
          style={{ height: "20rem", width: "35rem" }}
        >
          <Line
            data={ches_curc}
            options={{
              maintainAspectRatio: false,

              scales: {
                y: {
                  max: 150,
                  min: 0,
                  ticks: {
                    stepSize: 5,
                  },
                },
              },
              plugins: {
                title: {
                  display: true,
                  text: "Chest Circumference (CMS)",
                },
                legend: {
                  display: false,
                },
              },
            }}
          />
        </div>
        <div
          className="chart-container"
          style={{ height: "20rem", width: "35rem" }}
        >
          <Line
            data={mid_air}
            options={{
              maintainAspectRatio: false,

              scales: {
                y: {
                  max: 150,
                  min: 0,
                  ticks: {
                    stepSize: 5,
                  },
                },
              },
              plugins: {
                title: {
                  display: true,
                  text: "Mid Arm Circumference (CMS)",
                },
                legend: {
                  display: false,
                },
              },
            }}
          />
        </div>
        <div
          className="chart-container"
          style={{ height: "20rem", width: "35rem" }}
        >
          <Line
            data={arm_span}
            options={{
              maintainAspectRatio: false,

              scales: {
                y: {
                  max: 150,
                  min: 0,
                  ticks: {
                    stepSize: 5,
                  },
                },
              },
              plugins: {
                title: {
                  display: true,
                  text: "Arm Span (CMS)",
                },
                legend: {
                  display: false,
                },
              },
            }}
          />
        </div>
        <div
          className="chart-container"
          style={{ height: "20rem", width: "35rem" }}
        >
          <Line
            data={UpperSegment}
            options={{
              maintainAspectRatio: false,

              scales: {
                y: {
                  max: 150,
                  min: 0,
                  ticks: {
                    stepSize: 5,
                  },
                },
              },
              plugins: {
                title: {
                  display: true,
                  text: "Upper Segment (CMS)",
                },
                legend: {
                  display: false,
                },
              },
            }}
          />
        </div>
        <div
          className="chart-container"
          style={{ height: "20rem", width: "35rem" }}
        >
          <Line
            data={lSegment}
            options={{
              maintainAspectRatio: false,

              scales: {
                y: {
                  max: 150,
                  min: 0,
                  ticks: {
                    stepSize: 5,
                  },
                },
              },
              plugins: {
                title: {
                  display: true,
                  text: "Lower Segment (CMS)",
                },
                legend: {
                  display: false,
                },
              },
            }}
          />
        </div>
        <div
          className="chart-container"
          style={{ height: "20rem", width: "35rem" }}
        >
          <Line
            data={uratio}
            options={{
              maintainAspectRatio: false,

              scales: {
                y: {
                  max: 150,
                  min: 0,
                  ticks: {
                    stepSize: 5,
                  },
                },
              },
              plugins: {
                title: {
                  display: true,
                  text: "US/LS Ratio",
                },
                legend: {
                  display: false,
                },
              },
            }}
          />
        </div>
      </div>
    </>
  );
}
export default LineChart;
